import React from "react";

import Layout from "../components/layout";
import Seo from "../components/seo";

const NotFoundPage = () => (
  <Layout>
    <Seo title="404: Not found" />
    <div className={"page-block"}>
      <h1>Njet njet</h1>
      <p>Tavoittelemaasi sivua ei juuri nyt voida paikallistaa.</p>
    </div>
  </Layout>
);

export default NotFoundPage;
